import * as R from "ramda";
import { Component, PropsWithChildren } from "react";
import { getFields, getViewById, getViewByName } from "../helpers/AppHelper";
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
  wrapChildrenWith,
} from "../helpers/utils";
import { QueryAttributeJM } from "../models/Common/QueryAttributeJM";
import { RAFViewRow } from "../models/Common/RAFViewRow";

interface IProps {
  viewId?: string;
  viewName?: string;
  moduleName?: string;
  idField?: string;
  //children?: React.ReactNode;
  hideFields?: string[];
}

export interface IState {
  fields?: QueryAttributeJM[];
  allFields?: QueryAttributeJM[];
  view?: RAFViewRow;
  groupColumns?: string[];
  isLoading?: boolean;
  mobileColCount?: number;
}

class RAFFieldStateProvider extends Component<
  PropsWithChildren<IProps>,
  IState
> {
  _isMounted: boolean;

  constructor(props) {
    super(props);
    this.state = {
      fields: [],
      allFields: [],
      view: {},
      groupColumns: [],
      isLoading: true,
      mobileColCount: 0,
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.fetchFields();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { fields, allFields, view, groupColumns, isLoading, mobileColCount } =
      this.state;
    const { children, ...rest } = this.props;
    let children1 = null;
    //console.log('RAFFieldStateProvider this.props.children', children, rest);
    if (isLoading === true) {
      return <div className="h-100"></div>;
    } else {
      if (isNotNullAndUndefined(fields) && fields.length > 0) {
        children1 = wrapChildrenWith(this.props.children, {
          fields,
          allFields,
          view,
          groupColumns,
          mobileColCount,
          ...rest,
        });
        return <div className="h-100">{children1}</div>;
      } else {
        children1 = wrapChildrenWith(this.props.children, {
          ...rest,
        });
        return <div className="h-100">{children1}</div>;
      }
    }
  }

  fetchFields = () => {
    if (isNotNullAndUndefined(this.props.moduleName)) {
      getFields(this.props.moduleName).then((fields) => {
        //debugger;//this line is added by jagan to debug the issue
        const allFields = R.clone(fields);
        let groupColumns: string[] = null;
        if (this.props.viewId) {
          getViewById(this.props.viewId, this.props.moduleName).then((view) => {
            let filteredColumnArray: QueryAttributeJM[] = [];
            let mobileColCount =
              isNotNullAndUndefined(view) &&
              isNotNullAndUndefined(view.SettingsJson) &&
              isNotNullAndUndefined(view.SettingsJson.MobileColumnCount)
                ? view.SettingsJson.MobileColumnCount
                : 0;
            if (
              isNotNullAndUndefined(view) &&
              isNotNullAndUndefined(view.SelectColumnjson) &&
              isNotNullAndUndefined(view.SelectColumnjson.SelectColumns)
            ) {
              const filterAndSortArray = this.filterAndSortArray(
                fields,
                propertyOf<QueryAttributeJM>("PropertyName"),
                true,
                ...view.SelectColumnjson.SelectColumns
              );
              filteredColumnArray = isNotEmptyArray(filterAndSortArray)
                ? filterAndSortArray
                : R.clone(fields);
              filteredColumnArray = filteredColumnArray.filter(
                (x) => x.ShowInSelect === true
              );
            } else {
              filteredColumnArray = R.clone(fields);
              filteredColumnArray = filteredColumnArray.filter(
                (x) => x.ShowInSelect === true
              );
            }

            if (isNotEmptyArray(this.props.hideFields)) {
              filteredColumnArray = filteredColumnArray.filter(
                (item) => !this.props.hideFields.includes(item.PropertyName)
              );
            }

            if (
              isNotNullAndUndefined(view) &&
              isNotNullAndUndefined(view.GroupByJson) &&
              view.GroupByJson.length > 0
            ) {
              groupColumns = view.GroupByJson.map((e) => e.Field);
            }
            if (this._isMounted) {
              this.setState({
                fields: filteredColumnArray,
                allFields,
                view,
                groupColumns,
                isLoading: false,
                mobileColCount,
              });
            }
          });
        } else if (this.props.viewName) {
          getViewByName(this.props.viewName, this.props.moduleName).then(
            (view) => {
              let filteredColumnArray: QueryAttributeJM[] = [];
              let mobileColCount =
                isNotNullAndUndefined(view) &&
                isNotNullAndUndefined(view.SettingsJson) &&
                isNotNullAndUndefined(view.SettingsJson.MobileColumnCount)
                  ? view.SettingsJson.MobileColumnCount
                  : 0;

              if (
                isNotNullAndUndefined(view) &&
                isNotNullAndUndefined(view.SelectColumnjson) &&
                isNotNullAndUndefined(view.SelectColumnjson.SelectColumns)
              ) {
                filteredColumnArray = this.filterAndSortArray(
                  fields,
                  propertyOf<QueryAttributeJM>("PropertyName"),
                  true,
                  ...view.SelectColumnjson.SelectColumns
                );
                filteredColumnArray = filteredColumnArray.filter(
                  (x) => x.ShowInSelect === true
                );
              } else {
                filteredColumnArray = R.clone(fields);
                filteredColumnArray = filteredColumnArray.filter(
                  (x) => x.ShowInSelect === true
                );
              }
              if (
                isNotNullAndUndefined(view) &&
                isNotNullAndUndefined(view.GroupByJson) &&
                view.GroupByJson.length > 0
              ) {
                groupColumns = view.GroupByJson.map((e) => e.Field);
              }
              if (this._isMounted) {
                this.setState({
                  fields: filteredColumnArray,
                  allFields,
                  view,
                  groupColumns,
                  isLoading: false,
                  mobileColCount,
                });
              }
            }
          );
        } else {
          let filteredColumnArray: QueryAttributeJM[] = R.clone(fields);
          filteredColumnArray = isNotNullAndUndefined(filteredColumnArray)
            ? filteredColumnArray.filter((x) => x.ShowInSelect === true)
            : [];
          if (this._isMounted) {
            this.setState({
              fields: filteredColumnArray,
              allFields,
              groupColumns,
              isLoading: false,
            });
          }
        }
      });
    }
  };

  filterAndSortArray(
    columnsArray: QueryAttributeJM[],
    key: string,
    sortByArgs: boolean,
    ...args: string[]
  ) {
    let cloneArray: QueryAttributeJM[] = R.clone(columnsArray);
    let result = [];
    if (
      key !== null &&
      key !== undefined &&
      key.length > 0 &&
      args !== null &&
      args !== undefined &&
      args.length > 0
    ) {
      if (sortByArgs) {
        args.forEach((arg) => {
          //const obj = cloneArray.find(x => arg.includes(x[key]));
          const obj = isNotNullAndUndefined(cloneArray)
            ? cloneArray.find((x) => x.PropertyName === arg)
            : null;
          if (isNotNullAndUndefined(obj)) {
            result.push(obj);
          }
        });
      } else {
        result = isNotNullAndUndefined(cloneArray)
          ? cloneArray.filter((x) => args.includes(x[key]))
          : null;
      }
    } else {
      result = isNotNullAndUndefined(cloneArray) ? cloneArray : null;
    }
    if (isNotNullAndUndefined(result)) {
      result = result.map((item) => {
        if (isNotNullAndUndefined(item)) item.visible = true;
        return item;
      });
    }
    return result;
  }
}

export default RAFFieldStateProvider;
